import * as React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { compareAsc } from "date-fns";
import Button from "./style/Button";
import FreeSpace from "./style/FreeSpace";
import ModalDialog from "./style/ModalDialog";
import Breadcrumbs from "./style/Breadcrumbs";
import Link from "./style/Link";
import { useAPI } from "../api/API";
import { useCallback } from "react";
import CopyableInput from "./style/CopyableInput";

const License = () => {
    const params = useParams();

    const [keys, setKeys] = useState([]);
    const [login, setLogin] = useState("");
    const [pwd, setPwd] = useState("");
    const [clientLicenseType, setClientLicenseType] = useState("edu");
    const [licenseTypes, setLicenseTypes] = useState([]);
    const [organizationName, setOrganizationName] = useState("");
    const [organizationId, setOrganizationId] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const [msg, setMsg] = useState("");

    const api = useAPI();

    const getLicense = useCallback(async () => {
        try {
            const data = await api.getLicense({ id: params.lic_id });
            setClientLicenseType(data.new_client_license_type);
            setLogin(data.login);
            setPwd(data.password);
            setOrganizationName(data.organization);
            setOrganizationId(data.organization_id);

            setKeys(data.keys);
        } catch (error) {
            console.log(error);
        }
    }, [api, params]);

    useEffect(() => {
        api.getLicenseTypes({ product_code: "001" }).then((data) =>
            setLicenseTypes(data.items)
        );
        getLicense();
    }, [api, getLicense]);

    const updateLicense = async () => {
        try {
            await api.updateLicense({
                id: params.lic_id,
                new_client_license_type: clientLicenseType,
                product_code: "001",
                login: login,
                password: pwd,
            });

            await getLicense();
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };
    return (
        <>
            <Breadcrumbs fullwidth>
                <Link to="/dashboard">Главная</Link>
                <Link to="/organizations">Организации</Link>
                <Link to={`/organization/${organizationId}`}>
                    {organizationName}
                </Link>
                <Link to="#" aria-current="page">
                    {login}
                </Link>
            </Breadcrumbs>
            <div className="info-table-container w-100 mb-4">
                <p className="has-text-centered has-text-danger">{msg}</p>
                <table className="table is-fullwidth mb-0">
                    <thead>
                        <tr>
                            <th>Тип лицензии</th>
                            <th>Логин</th>
                            <th>Пароль</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="select">
                                    <select
                                        value={clientLicenseType}
                                        onChange={(e) =>
                                            setClientLicenseType(e.target.value)
                                        }
                                    >
                                        {licenseTypes.map((licenseType) => (
                                            <option
                                                value={licenseType.type}
                                                key={licenseType.type}
                                            >
                                                {licenseType.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                            <td>
                                <CopyableInput
                                    className="input"
                                    value={login}
                                    onChange={(e) => setLogin(e.target.value)}
                                />
                            </td>
                            <td>
                                <CopyableInput
                                    className="input"
                                    value={pwd}
                                    onChange={(e) => setPwd(e.target.value)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="is-flex is-justify-content-flex-end">
                    <Button withBorders onClick={() => setModalOpen(true)}>
                        Сохранить
                    </Button>
                </div>
            </div>
            <div className="table-container w-100">
                <table className="table small is-fullwidth">
                    <thead>
                        <tr>
                            <th>Номер ключа</th>
                            <th>Мак адрес</th>
                            <th>Активирован</th>
                            <th>Дата активации</th>
                            <th>Срок истечения</th>
                            <th>Удаление / продление</th>
                        </tr>
                    </thead>
                    <tbody>
                        {keys.map((o, i) => (
                            <tr
                                key={o.id}
                                className={
                                    compareAsc(
                                        new Date(),
                                        new Date(o.expires_at)
                                    ) > 0
                                        ? "has-text-danger"
                                        : ""
                                }
                            >
                                <td> {i + 1}</td>
                                <td> {o.mac_address}</td>
                                <td>
                                    {o.activated
                                        ? "Активирован"
                                        : "Не активирован"}
                                </td>

                                <td>
                                    {new Date(
                                        o.activate_from
                                    ).toLocaleDateString("ru-RU")}
                                </td>
                                <td>
                                    {new Date(o.expires_at).toLocaleDateString(
                                        "ru-RU"
                                    )}
                                </td>
                                <td>
                                    <Link to={`./key/${o.id}`}>Открыть</Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <FreeSpace />
            <div className="is-flex is-justify-content-space-between w-100">
                <Button withBorders href={"./key/add"}>
                    Добавить ключи
                </Button>
                <Button withBorders href={"./renew"}>
                    Продлить ключи
                </Button>
            </div>
            <ModalDialog
                open={modalOpen}
                onChange={(success) => {
                    setModalOpen(false);
                    if (success) {
                        updateLicense();
                    }
                }}
            >
                Вы уверены, что хотите изменить данные лицензии?
            </ModalDialog>
        </>
    );
};

export default License;
