import * as React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Fuse from "fuse.js";
import FreeSpace from "./style/FreeSpace";
import Button from "./style/Button";
import ModalDialog from "./style/ModalDialog";
import Breadcrumbs from "./style/Breadcrumbs";
import Link from "./style/Link";
import { useAPI } from "../api/API";
import { useCallback } from "react";
import CopyableInput from "./style/CopyableInput";

const Organization = () => {
    const params = useParams();

    const [licenses, setLicenses] = useState([]);
    const [licenseTypes, setLicenseTypes] = useState(new Map());
    const [searcher, setSearcher] = useState(() => new Fuse([]));
    const [searchString, setSearchString] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const api = useAPI();

    const [name, setName] = useState("");
    const [inn, setInn] = useState("");
    const [contact, setContact] = useState("");
    const [phone, setPhone] = useState("");

    const [msg, setMsg] = useState("");

    const getOrganization = useCallback(async () => {
        try {
            const data = await api.getOrganization({ id: params.org_id });
            setName(data.name ?? "");
            setContact(data.email ?? "");
            setPhone(data.phone ?? "");
            setInn(data.inn ?? "");

            setLicenses(data.licenses);
            setSearcher(
                new Fuse(data.licenses, {
                    keys: ["login", "password"],
                })
            );
        } catch (error) {
            console.log(error);
        }
    }, [params, api]);

    useEffect(() => {
        api.getLicenseTypes({ product_code: "001" }).then((data) => {
            const items = data.items;
            const pairs = items.map((item) => [item.type, item.name]);
            setLicenseTypes(new Map(pairs));
        });
        getOrganization();
    }, [getOrganization, api]);

    const updateOrganization = async () => {
        if (name.length < 1) {
            setMsg("заполните все поля");
            return 0;
        }
        try {
            await api.updateOrganization({
                id: params.org_id,
                name: name,
                inn: inn,
                email: contact,
                phone: phone,
            });
            await getOrganization();
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    const deleteLicense = async (id) => {
        await api.deleteLicense({ id });
        await getOrganization();
    };

    return (
        <>
            <Breadcrumbs fullwidth>
                <Link to="/dashboard">Главная</Link>
                <Link to="/organizations">Организации</Link>
                <Link to="#" aria-current="page">
                    {name}
                </Link>
            </Breadcrumbs>
            <div className="info-table-container w-100 mb-4">
                <p className="has-text-centered has-text-danger">{msg}</p>
                <table className="table is-fullwidth mb-0">
                    <thead>
                        <tr>
                            <th>Наименование организации</th>
                            <th>ИНН</th>
                            <th>E-MAIL</th>
                            <th>Контактный телефон</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <CopyableInput
                                    className="input"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </td>
                            <td>
                                <CopyableInput
                                    className="input"
                                    value={inn}
                                    onChange={(e) => setInn(e.target.value)}
                                />
                            </td>

                            <td>
                                <CopyableInput
                                    className="input"
                                    value={contact}
                                    onChange={(e) => setContact(e.target.value)}
                                />
                            </td>
                            <td>
                                <CopyableInput
                                    className="input"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="is-flex is-justify-content-flex-end">
                    <Button withBorders onClick={() => setModalOpen(true)}>
                        Сохранить
                    </Button>
                </div>
            </div>
            <div className="block w-100">
                <div className="field">
                    <label className="label has-text-white">
                        Поиск лицензии
                    </label>
                    <div className="control">
                        <input
                            type="text"
                            className="input has-text-left"
                            placeholder="Начните вводить название лицензии..."
                            onChange={(e) =>
                                setSearchString(e.currentTarget.value)
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="table-container w-100">
                <table className="table small is-fullwidth">
                    <thead>
                        <tr>
                            <th>Тип лицензии</th>
                            <th>Логин</th>
                            <th>Пароль</th>
                            <th>Общее количество ключей</th>
                            <th>Количество активированных ключей</th>
                            <th>Закончившиеся ключи</th>
                            <th>Подробная информация</th>
                            <th>Удалить лицензию</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(searchString.length === 0
                            ? licenses
                            : searcher.search(searchString).map((o) => o.item)
                        ).map((o) => (
                            <tr
                                key={o.id}
                                className={
                                    o.keys_expired > 0 ? "has-text-danger" : ""
                                }
                            >
                                <td>
                                    {licenseTypes.get(
                                        o.new_client_license_type
                                    ) ?? o.new_client_license_type}
                                </td>
                                <td> {o.login}</td>
                                <td> {o.password}</td>

                                <td> {o.keys_amount}</td>
                                <td> {o.keys_activated}</td>
                                <td> {o.keys_expired}</td>
                                <td>
                                    <Link to={`./license/${o.id}`}>
                                        Открыть
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        button
                                        onClick={() => deleteLicense(o.id)}
                                    >
                                        Удалить
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <FreeSpace />
            <div className="w-100">
                <Button
                    withBorders
                    href={`./license/add?org_id=${params.org_id}`}
                >
                    Добавить лицензию
                </Button>
            </div>
            <ModalDialog
                open={modalOpen}
                onChange={(success) => {
                    setModalOpen(false);
                    if (success) {
                        updateOrganization();
                    }
                }}
            >
                Вы уверены что хотите изменить данные организации?
            </ModalDialog>
        </>
    );
};

export default Organization;
