import { ClipboardCopy } from "lucide-react";

const CopyableInput = (props) => {
    return (
        <div className="copyable">
            <input
                className="input"
                value={props.value}
                onChange={props.onChange}
            />
            <ClipboardCopy
                className="copy-icon"
                onClick={(e) => {
                    e.currentTarget.previousElementSibling.select();
                    document.execCommand("copy");
                }}
            />
        </div>
    );
};

export default CopyableInput;
