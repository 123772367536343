import Button from "./Button";

const ModalDialog = (props) => (
    <div className={"modal" + (props.open ? " is-active" : "")}>
        <div
            className="modal-background"
            onClick={() => props.onChange(false)}
        ></div>
        <div className="modal-dialog">
            <div className="mt-2 mb-5">{props.children}</div>
            <div className="buttons is-justify-content-center gap-16">
                <Button withBorders onClick={() => props.onChange(false)}>
                    Нет
                </Button>
                <Button withBorders onClick={() => props.onChange(true)}>
                    Да
                </Button>
            </div>
            <div className="broken"></div>
        </div>
    </div>
);

export default ModalDialog;
