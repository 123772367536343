const Breadcrumbs = (props) => {
    const classNames = ["breadcrumb"];
    if (props.fullwidth) {
        classNames.push("w-100");
    }

    const last = (i) =>
        i + 1 === props.children.length ? { className: "is-active" } : {};

    return (
        <nav className={classNames.join(" ")} aria-label="breadcrumbs">
            <ul>
                {props.children.map((c, i) => (
                    <li key={i} {...last(i)}>
                        {c}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Breadcrumbs;
