import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import FreeSpace from "./style/FreeSpace";
import Button from "./style/Button";
import Breadcrumbs from "./style/Breadcrumbs";
import Link from "./style/Link";
import { useAPI } from "../api/API";
import { useCallback } from "react";

const AddOrganization = () => {
    const params = useParams();
    const api = useAPI();

    const [login, setLogin] = useState("");
    const [clientLicenseType, setClientLicenseType] = useState("pro");
    const [licenseTypes, setLicenseTypes] = useState([]);
    const [organizationName, setOrganizationName] = useState("");
    const [expDate, setExpDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [infinite, setInfinite] = useState(false);

    const [msg, setMsg] = useState("");
    const [loginError, setLoginError] = useState("");

    const navigate = useNavigate();

    const getOrganization = useCallback(async () => {
        try {
            const data = await api.getOrganization({ id: params.org_id });
            setOrganizationName(data.name);
        } catch (error) {
            console.log(error);
        }
    }, [api, params]);

    useEffect(() => {
        api.getLicenseTypes({ product_code: "001" }).then((data) =>
            setLicenseTypes(data.items)
        );
        getOrganization();
    }, [getOrganization, api]);

    const generateLicenseLogin = async () => {
        setLogin(await api.generateLogin());
    };

    const Send = async () => {
        if (login.length < 1 || clientLicenseType.length < 1) {
            setMsg("заполните все поля");
            return 0;
        }

        try {
            const amountNumber = Number(amount);
            const number_of_keys = isFinite(amountNumber) ? amountNumber : 0;
            expDate.setHours(23, 59, 59);

            const data = await api.createLicense({
                organization: params.org_id,
                login: login,
                new_client_license_type: clientLicenseType,
                product_code: "001",
                number_of_keys,
                expires_at: expDate.toISOString(),
                activate_from: startDate.toISOString(),
            });

            navigate(`/organization/${params.org_id}/license/${data.pk}`);
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMsg(error.response.data.msg);
                setLoginError(error.response.data.login);
            }
        }
    };
    return (
        <>
            <Breadcrumbs fullwidth>
                <Link to="/dashboard">Главная</Link>
                <Link to="/organizations">Организации</Link>
                <Link to={`/organization/${params.org_id}`}>
                    {organizationName}
                </Link>
                <Link aria-current="page">Добавить лицензию</Link>
            </Breadcrumbs>
            <p className="has-text-centered has-text-danger">{msg}</p>
            <table className="table  is-fullwidth">
                <thead>
                    <tr>
                        <th>Логин</th>

                        <th>Тип лицензии</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="is-flex is-flex-direction-row gap-16">
                                <Button
                                    withBorders
                                    onClick={() => generateLicenseLogin()}
                                >
                                    Сгенерировать
                                </Button>
                                <div className="is-flex-grow-1 is-flex-shrink-1 is-flex is-flex-direction-column">
                                    <input
                                        className="input"
                                        value={login}
                                        onChange={(e) =>
                                            setLogin(e.target.value)
                                        }
                                    />
                                    <span className="has-text-danger">
                                        {loginError}
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="select">
                                <select
                                    value={clientLicenseType}
                                    onChange={(e) =>
                                        setClientLicenseType(e.target.value)
                                    }
                                >
                                    {licenseTypes.map((licenseType) => (
                                        <option
                                            value={licenseType.type}
                                            key={licenseType.type}
                                        >
                                            {licenseType.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table className="table is-fullwidth">
                <thead>
                    <tr>
                        <th>Количество </th>
                        <th>Дата активации</th>
                        <th>Бесконечный</th>
                        <th>Дата истечения</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="number"
                                className="input"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </td>

                        <td>
                            <DatePicker
                                className="input"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </td>
                        <td>
                            <input
                                type="checkbox"
                                className="checkbox"
                                defaultChecked={infinite}
                                onChange={(e) => {
                                    setInfinite(e.currentTarget.checked);
                                    if (e.currentTarget.checked) {
                                        setExpDate(new Date(2124, 0, 1));
                                    } else {
                                        setExpDate(new Date());
                                    }
                                }}
                            />
                        </td>
                        <td>
                            <DatePicker
                                className="input"
                                disabled={infinite}
                                selected={expDate}
                                onChange={(date) => setExpDate(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="is-flex w-100 is-justify-content-space-between">
                <Button withBorders href={`/organization/${params.org_id}`}>
                    Отмена
                </Button>
                <Button withBorders onClick={Send}>
                    Добавить
                </Button>
            </div>
            <FreeSpace />
        </>
    );
};

export default AddOrganization;
