import React from "react";

import { Link } from "react-router-dom";
import Button from "./style/Button";
import { Navigate } from 'react-router-dom';

const Dashboard = () => {
    return (
        <>
            <Navigate to="/organizations" />
            <div className="is-flex is-justify-content-center is-flex-grow-1 is-align-items-center">
                <Link to="/organizations" className="mr-5">
                    <Button big>Организации</Button>
                </Link>
                <Button big>Демо лицензии</Button>
            </div>
        </>
    );
};

export default Dashboard;
