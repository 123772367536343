import axios from "axios";
import jwtDecode from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

const APIContext = createContext(null);

const API = (props) => {
    const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage(
        "accessToken",
        null
    );
    const [refreshToken, setRefreshToken, removeRefreshToken] = useLocalStorage(
        "refreshToken",
        null
    );
    const [fio, setFio, removeFio] = useLocalStorage("fio", null);
    const [username, setUsername, removeUsername] = useLocalStorage(
        "username",
        null
    );
    const [expire, setExpire, removeExpire] = useLocalStorage("expire", null);

    const updateApi = (oldApi) => {
        if (accessToken && refreshToken && username && expire) {
            if (oldApi && oldApi.type === "logged") {
                oldApi.updateData({
                    accessToken,
                    refreshToken,
                    fio,
                    username,
                    expire,
                });
                return oldApi;
            }
            return new LoggedAPI(
                {
                    accessToken,
                    refreshToken,
                    fio,
                    username,
                    expire,
                },
                (updateData) => {
                    setAccessToken(updateData.accessToken);
                    setRefreshToken(updateData.refreshToken);
                    setExpire(updateData.expire);
                },
                () => {
                    removeAccessToken();
                    removeRefreshToken();
                    removeFio();
                    removeUsername();
                    removeExpire();
                }
            );
        } else {
            return new UnloggedAPI((loginData) => {
                setAccessToken(loginData.accessToken);
                setRefreshToken(loginData.refreshToken);
                setFio(loginData.fio);
                setUsername(loginData.username);
                setExpire(loginData.expire);
            });
        }
    };

    const [api, setApi] = useState(updateApi);
    useEffect(() => {
        setApi(updateApi);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken, refreshToken, fio, username, expire]);

    return (
        <APIContext.Provider value={api}>{props.children}</APIContext.Provider>
    );
};

const ROOT = `https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin`;

class UnloggedAPI {
    type = "unlogged";

    constructor(login) {
        this._login = login;
        this.axios = axios.create({
            baseURL: ROOT,
        });
    }

    async login(username, password) {
        const response = await this.axios.post("/auth/", {
            username: username,
            password: password,
        });

        if (response.status !== 200) return;
        console.log(response);

        const { access, refresh, fio, lifetime } = response.data;
        const expire = Date.now() + lifetime * 1000;
        this._login({
            accessToken: access,
            refreshToken: refresh,
            fio,
            username,
            expire,
        });
    }
}

class LoggedAPI {
    type = "logged";

    constructor(data, update, logout) {
        this.updateData(data);
        this._update = update;
        this._logout = logout;
    }

    get accessToken() {
        return this._data.accessToken;
    }
    get refreshToken() {
        return this._data.refreshToken;
    }
    get fio() {
        return this._data.fio;
    }
    get username() {
        return this._data.username;
    }
    get expire() {
        return this._data.expire;
    }

    async logout() {
        this._logout();

        if (this.expire > Date.now()) {
            try {
                await this.axios.post(`/logout/`, {
                    refresh: this.refreshToken,
                });
            } catch (e) {
                console.error(e);
            }
        }
    }

    async refresh() {
        if (this.expire > Date.now()) {
            return;
        }

        try {
            const response = await this.axios.post(`/refresh/`, {
                refresh: this.refreshToken,
            });
            const { access, refresh } = response.data;
            const expire = jwtDecode(access).exp * 1000;
            this._update({
                accessToken: access,
                refreshToken: refresh,
                expire,
            });
        } catch (e) {
            console.error(e);
            this._logout();
            throw e;
        }
    }

    updateData(data) {
        this._data = data;
        this.axios = axios.create({
            baseURL: ROOT,
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
            },
        });
    }

    async getOrganizations({ limit, offset, search }) {
        await this.refresh();

        const response = await this.axios.get(`/organizations/`, {
            params: { limit, offset, search },
        });

        return response.data;
    }

    async deleteOrganization({ id }) {
        await this.refresh();

        const response = await this.axios.delete(`/organizations/${id}/`);
        return response.data;
    }

    async createOrganization({ name, inn, email, phone }) {
        await this.refresh();

        const response = await this.axios.post(`/organizations/`, {
            name,
            inn,
            email,
            phone,
        });
        return response.data;
    }

    async getOrganization({ id }) {
        await this.refresh();

        const response = await this.axios.get(`/organizations/${id}/`);
        return response.data;
    }

    async updateOrganization({ id, name, inn, email, phone }) {
        await this.refresh();

        const response = await this.axios.post(
            `/organizations/${id}/update_organization/`,
            { name, inn, email, phone }
        );
        return response.data;
    }

    async getLicenseTypes({ product_code }) {
        await this.refresh();

        const response = await this.axios.post(`/licenses-types/`, {
            product_code,
        });
        return response.data;
    }

    async deleteLicense({ id }) {
        await this.refresh();

        const response = await this.axios.delete(`/licenses/${id}/`);
        return response.data;
    }

    async createLicense({
        organization,
        login,
        new_client_license_type,
        product_code,
        number_of_keys,
        expires_at,
        activate_from,
    }) {
        await this.refresh();

        const response = await this.axios.post(`/licenses/`, {
            organization,
            login,
            new_client_license_type,
            product_code,
            number_of_keys,
            expires_at,
            activate_from,
        });
        return response.data;
    }

    async getLicense({ id }) {
        await this.refresh();

        const response = await this.axios.get(`/licenses/${id}/`);
        return response.data;
    }

    async updateLicense({
        id,
        login,
        new_client_license_type,
        product_code,
        password,
    }) {
        await this.refresh();

        const response = await this.axios.put(`/licenses/${id}/`, {
            password,
            login,
            new_client_license_type,
            product_code,
        });
        return response.data;
    }

    async createKeys({ id, expires_at, number_of_keys, activate_from }) {
        await this.refresh();

        const response = await this.axios.patch(`/licenses/${id}/add_keys/`, {
            expires_at,
            number_of_keys,
            activate_from,
        });
        return response.data;
    }

    async renewKeys({ id, activate_from, expires_at }) {
        await this.refresh();

        const response = await this.axios.patch(
            `/licenses/${id}/prolongate_keys/`,
            {
                activate_from,
                expires_at,
            }
        );
        return response.data;
    }

    async getKey({ id }) {
        await this.refresh();

        const response = await this.axios.get(`/keys/${id}/`);
        return response.data;
    }

    async updateKey({ id, expires_at, activate_from }) {
        await this.refresh();

        const response = await this.axios.put(`/keys/${id}/`, {
            expires_at,
            activate_from,
        });
        return response.data;
    }

    async deleteKey({ id }) {
        await this.refresh();

        const response = await this.axios.delete(`/keys/${id}/`);
        return response.data;
    }

    async generateLogin() {
        await this.refresh();

        const response = await this.axios.post(`/generate-login/`);
        return response.data.login;
    }
}

export default API;
export const useAPI = () => useContext(APIContext);
