import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalDialog from "./style/ModalDialog";
import { useAPI } from "../api/API";

const Navbar = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const api = useAPI();

    return (
        <div className="is-flex is-flex-direction-row is-align-items-center gap-16 w-100 mb-5 quadro-navbar">
            <Link to="/dashboard">
                <img src="/logo-adm.svg" alt="КвадроСим Adm" />
            </Link>
            <img src="/group.svg" alt="" />
            <img src="/user-icon.png" alt="" />
            <div className="username is-flex-grow-2">
                {api.fio || api.username}
            </div>
            <button
                className="button logout-button"
                onClick={() => setModalOpen(true)}
            >
                Выход <img src="/exit-icon.svg" alt="" className="ml-2" />
            </button>
            <ModalDialog
                open={modalOpen}
                onChange={(success) => {
                    setModalOpen(false);
                    if (success) {
                        api.logout();
                    }
                }}
            >
                Вы уверены, что хотите выйти?
            </ModalDialog>
        </div>
    );
};

export default Navbar;
