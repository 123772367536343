import { Outlet, BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Navbar from "./components/Navbar";
import Register from "./components/Register";
import Organizations from "./components/Organizations";
import Organization from "./components/Organization";
import AddOrganization from "./components/AddOrganization";
import Key from "./components/Key";
import AddKeys from "./components/AddKeys";
import LongateLicense from "./components/LongateLicense";
import AddLicense from "./components/AddLicense";
import License from "./components/License";
import API from "./api/API";
import LoginRedirector from "./api/LoginRedirector";

function App() {
    const keyRoutes = (
        <Route path="key">
            <Route path="add" element={<AddKeys />} />
            <Route path=":key_id" element={<Key />} />
        </Route>
    );
    const licenseRoutes = (
        <Route path="license">
            <Route path="add" element={<AddLicense />} />
            <Route path=":lic_id">
                <Route index element={<License />} />
                <Route path="renew" element={<LongateLicense />} />
                {keyRoutes}
            </Route>
        </Route>
    );
    const organizationRoutes = (
        <Route path="organization">
            <Route path="add" element={<AddOrganization />} />
            <Route path=":org_id">
                <Route index element={<Organization />} />
                {licenseRoutes}
            </Route>
        </Route>
    );

    return (
        <API>
            <BrowserRouter>
                <Routes>
                    <Route element={<LoginRedirector />}>
                        <Route path="/" element={<Login />} />

                        <Route path="/register" element={<Register />} />

                        <Route path="/" element={<LayoutsWithNavbar />}>
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route
                                path="organizations"
                                element={<Organizations />}
                            />
                            {organizationRoutes}
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </API>
    );
}
function LayoutsWithNavbar() {
    return (
        <>
            <Navbar />
            <Outlet />
        </>
    );
}
export default App;
