import * as React from "react";
import { useEffect, useState } from "react";
import FreeSpace from "./style/FreeSpace";
import Button from "./style/Button";
import Pagination from "./style/Pagination";
import ModalDialog from "./style/ModalDialog";
import Breadcrumbs from "./style/Breadcrumbs";
import Link from "./style/Link";
import { useAPI } from "../api/API";
import { useDebounceCallback } from "usehooks-ts";
import { useCallback } from "react";

const LIMIT = 25;
const DEBOUNCE_MS = 500;

const Organizations = () => {
    const [organizations, setOrganizations] = useState([]);
    const [pageAndSearch, setPageAndSearch] = useState({ page: 1, search: "" });
    const [total, setTotal] = useState(1);
    const [deleteOrgId, setDeleteOrgId] = useState(0);
    const setSearch = useDebounceCallback((search) => {
        setPageAndSearch({ page: 1, search });
    }, DEBOUNCE_MS);
    const api = useAPI();

    const getOrganizations = useCallback(async () => {
        try {
            const data = await api.getOrganizations({
                limit: LIMIT,
                offset: (pageAndSearch.page - 1) * LIMIT,
                search: pageAndSearch.search.length > 0 ? pageAndSearch.search : null,
            });
            console.log(data);
            setOrganizations(data.results);
            setTotal(Math.max(1, Math.ceil(data.count / LIMIT)));
        } catch (error) {
            console.log(error);
            if (error.status === 401) alert(error.data.detail);
        }
    }, [pageAndSearch, api]);

    useEffect(() => {
        getOrganizations();
    }, [pageAndSearch, getOrganizations]);

    const deleteOrganization = async (id) => {
        await api.deleteOrganization({ id });
        setOrganizations([]);
        await getOrganizations();
    };

    return (
        <>
            <Breadcrumbs fullwidth>
                <Link to="/dashboard">Главная</Link>
                <Link aria-current="page">Организации</Link>
            </Breadcrumbs>

            <div className="block w-100">
                <div className="field">
                    <label className="label has-text-white">
                        Поиск организации
                    </label>
                    <div className="control">
                        <input
                            type="text"
                            className="input has-text-left"
                            placeholder="Начните вводить название организации..."
                            onChange={(e) => {
                                setSearch(e.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="table-container is-flex-shrink-1 w-100">
                <table className="table small is-fullwidth">
                    <thead>
                        <tr>
                            <th>Наименование организации</th>
                            <th>Количество лицензий</th>
                            <th>Общее количество ключей </th>
                            <th>Количество активированных ключей</th>
                            <th>Закончившиеся ключи</th>
                            <th>Специалист отдела продаж</th>
                            <th>Подробная информация</th>
                            <th>Удалить организацию</th>
                        </tr>
                    </thead>
                    <tbody>
                        {organizations.map((o) => (
                            <tr
                                key={o.name}
                                className={
                                    o.keys_expired > 0 ? "has-text-danger" : ""
                                }
                            >
                                <td>{o.name}</td>
                                <td>{o.licenses_count}</td>

                                <td>{o.keys_amount}</td>
                                <td>{o.keys_activated}</td>
                                <td>{o.keys_expired}</td>
                                <td>{o.sales_specialist_org}</td>
                                <td>
                                    <Link to={`/organization/${o.id}`}>
                                        Открыть
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        button
                                        onClick={() => setDeleteOrgId(o.id)}
                                    >
                                        Удалить
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <FreeSpace />
            <div className="is-flex w-100">
                <Button withBorders href="/organization/add">
                    Добавить организацию
                </Button>
                <FreeSpace />
                <Pagination
                    current={pageAndSearch.page}
                    total={total}
                    onChange={(page) => setPageAndSearch((o) => ({ ...o, page}))}
                />
            </div>
            <ModalDialog
                open={deleteOrgId !== 0}
                onChange={(success) => {
                    if (success) {
                        deleteOrganization(deleteOrgId);
                    }
                    setDeleteOrgId(0);
                }}
            >
                Вы уверены, что хотите удалить организацию?
            </ModalDialog>
        </>
    );
};

export default Organizations;
