import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FreeSpace from "./style/FreeSpace";
import Button from "./style/Button";
import Breadcrumbs from "./style/Breadcrumbs";
import Link from "./style/Link";
import { useAPI } from "../api/API";

const Key = () => {
    const params = useParams();

    const [key, setKey] = useState([]);
    const [expDate, setExpDate] = useState(new Date());
    const [actDate, setActDate] = useState(new Date());

    const [login, setLogin] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationId, setOrganizationId] = useState(0);

    const history = useNavigate();
    const api = useAPI();
    const [msg, setMsg] = useState("");

    useEffect(() => {
        const getKey = async () => {
            try {
                const data = await api.getKey({ id: params.key_id });
                setKey(data);
                setExpDate(new Date(data.expires_at));
                setActDate(new Date(data.activate_from));
                await getLicense(data.license_id);
            } catch (error) {
                console.log(error);
            }
        };

        const getLicense = async (id) => {
            try {
                const data = await api.getLicense({ id });
                setLogin(data.login);
                setOrganizationName(data.organization);
                setOrganizationId(data.organization_id);
            } catch (error) {
                console.log(error);
            }
        };

        getKey();
    }, [api, params]);

    const updateKey = async () => {
        try {
            expDate.setHours(23, 59, 59);
            await api.updateKey({
                id: key.id,
                expires_at: expDate.toISOString(),
                activate_from: actDate.toISOString(),
            });
            history(`../..`);
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    const deleteKey = async () => {
        try {
            await api.deleteKey({ id: key.id });
            history(`../..`);
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <>
            <Breadcrumbs fullwidth>
                <Link to="/dashboard">Главная</Link>
                <Link to="/organizations">Организации</Link>
                <Link to={`/organization?id=${organizationId}`}>
                    {organizationName}
                </Link>
                <Link to={`../..`}>{login}</Link>
                <Link to="#" aria-current="page">
                    Ключ
                </Link>
            </Breadcrumbs>
            <p className="has-text-centered has-text-danger">{msg}</p>
            <table className="table  is-fullwidth">
                <thead>
                    <tr>
                        <th>MAC</th>
                        <th>Активация</th>
                        <th>Срок активации</th>
                        <th>Срок истечения</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{key.mac_address}</td>
                        <td>
                            {key.activated ? "Активирован" : "Не активирован"}
                        </td>
                        <td>
                            <DatePicker
                                className="input"
                                selected={actDate}
                                onChange={(date) => setActDate(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </td>
                        <td>
                            <DatePicker
                                className="input"
                                selected={expDate}
                                onChange={(date) => setExpDate(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="is-flex w-100 gap-16">
                <Button withBorders href={`../..`}>
                    Отмена
                </Button>
                <FreeSpace />
                <Button withBorders onClick={deleteKey}>
                    Удалить
                </Button>
                <Button withBorders onClick={updateKey}>
                    Продлить
                </Button>
            </div>
            <FreeSpace />
        </>
    );
};

export default Key;
