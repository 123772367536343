import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import FreeSpace from "./style/FreeSpace";
import Button from "./style/Button";
import Breadcrumbs from "./style/Breadcrumbs";
import Link from "./style/Link";
import { useAPI } from "../api/API";

const AddLicense = () => {
    const [name, setName] = useState("");
    const [inn, setInn] = useState("");
    const [contact, setContact] = useState("");
    const [phone, setPhone] = useState("");

    const [msg, setMsg] = useState("");
    const [nameError, setNameError] = useState("");
    
    const navigate = useNavigate();
    const api = useAPI();

    const Send = async () => {
        if (name.length < 1) {
            setMsg("заполните название");
            return 0;
        }
        try {
            const data = await api.createOrganization({ name, inn, contact, phone })
            navigate(`/organization/${data.pk}`);
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMsg(error.response.data.msg);
                setNameError(error.response.data.name);
            }
        }
    };
    return (
        <>
            <Breadcrumbs fullwidth>
                <Link to="/dashboard">Главная</Link>
                <Link to="/organizations">Организации</Link>
                <Link aria-current="page">
                    Добавить организацию
                </Link>
            </Breadcrumbs>
            <p className="has-text-centered has-text-danger">{msg}</p>
            <table className="table  is-fullwidth">
                <thead>
                    <tr>
                        <th>Наименование организации</th>
                        <th>ИНН</th>
                        <th>E-MAIL</th>
                        <th>Контактный телефон</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="is-flex is-flex-direction-column">
                                <input
                                    className="input"
                                    placeholder="ООО «Рога И Копыта»"
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <span className="has-text-danger">
                                    {nameError}
                                </span>
                            </div>
                        </td>
                        <td>
                            <input
                                className="input"
                                placeholder="922390874108"
                                onChange={(e) => setInn(e.target.value)}
                            />
                        </td>

                        <td>
                            <input
                                className="input"
                                placeholder="ivanov.ivan@mail.ru"
                                onChange={(e) => setContact(e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                className="input"
                                placeholder="+7 (999) 99-99-99"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="is-flex is-justify-content-space-between w-100">
                <Button withBorders href={"/organizations"}>
                    Отмена
                </Button>
                <Button withBorders onClick={Send}>
                    Добавить
                </Button>
            </div>
            <FreeSpace />
        </>
    );
};

export default AddLicense;
