import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./style/Button";
import { useAPI } from "../api/API";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const api = useAPI();

    const Auth = async (e) => {
        e.preventDefault();

        try {
            await api.login(username, password);
            navigate("/dashboard");
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <form onSubmit={Auth} className="login-form">
            <p className="has-text-centered has-text-danger">{msg}</p>
            <div className="field mt-5 has-text-centered">
                <img
                    src="logo-adm.svg"
                    className="is-fullwidth"
                    alt="КвадроСим Adm"
                />
            </div>
            <div className="field mt-6">
                <div className="controls">
                    <input
                        type="text"
                        className="input"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
            </div>
            <div className="field mt-5">
                <div className="controls">
                    <input
                        type="password"
                        className="input"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
            </div>
            <div className="field mt-5 ">
                <Button login className="is-fullwidth">
                    Войти
                </Button>
            </div>
        </form>
    );
};

export default Login;
