import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FreeSpace from "./style/FreeSpace";
import Button from "./style/Button";
import Breadcrumbs from "./style/Breadcrumbs";
import Link from "./style/Link";
import { useAPI } from "../api/API";
import { endOfDay, startOfDay } from "date-fns";

const AddKeys = () => {
    const params = useParams();
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const api = useAPI();
    const [expDate, setExpDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [infinite, setInfinite] = useState(false);

    const [login, setLogin] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationId, setOrganizationId] = useState(0);

    useEffect(() => {
        const getLicense = async (id) => {
            try {
                const data = await api.getLicense({ id });
                setLogin(data.login);
                setOrganizationName(data.organization);
                setOrganizationId(data.organization_id);
            } catch (error) {
                console.log(error);
            }
        };

        getLicense(params.lic_id);
    }, [params, api]);

    const addKeys = async () => {
        const startDateFixed = startOfDay(startDate);
        console.log(startDate.toISOString(), startDateFixed.toISOString());
        const expDateFixed = endOfDay(expDate);
        try {
            await api.createKeys({
                id: params.lic_id,
                expires_at: expDateFixed.toISOString(),
                number_of_keys: parseInt(amount),
                activate_from: startDateFixed.toISOString(),
            });
            navigate(`../..`);
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <>
            <Breadcrumbs fullwidth>
                <Link to="/dashboard">Главная</Link>
                <Link to="/organizations">Организации</Link>
                <Link
                    to={`/organization/${organizationId}?id=${organizationId}`}
                >
                    {organizationName}
                </Link>
                <Link to={`../..`}>{login}</Link>
                <Link to="#" aria-current="page">
                    Добавить ключи
                </Link>
            </Breadcrumbs>
            <p className="has-text-centered">{msg}</p>
            <table className="table is-fullwidth">
                <thead>
                    <tr>
                        <th>Количество </th>
                        <th>Дата активации</th>
                        <th>Бесконечный</th>
                        <th>Дата истечения</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="number"
                                className="input"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </td>

                        <td>
                            <DatePicker
                                className="input"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </td>
                        <td>
                            <input
                                type="checkbox"
                                className="checkbox"
                                defaultChecked={infinite}
                                onChange={(e) => {
                                    setInfinite(e.currentTarget.checked);
                                    if (e.currentTarget.checked) {
                                        setExpDate(new Date(2124, 0, 1));
                                    } else {
                                        setExpDate(new Date());
                                    }
                                }}
                            />
                        </td>
                        <td>
                            <DatePicker
                                className="input"
                                disabled={infinite}
                                selected={expDate}
                                onChange={(date) => setExpDate(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="is-flex is-justify-content-space-between w-100">
                <Button withBorders href={`../..`}>
                    Отмена
                </Button>
                <Button withBorders onClick={addKeys}>
                    Добавить
                </Button>
            </div>
            <FreeSpace />
        </>
    );
};

export default AddKeys;
