import { Link as RouterLink } from "react-router-dom";

function Link(props) {
    if (props.button) {
        return <button className="link" onClick={props.onClick}>{props.children}</button>;
    }

    return (
        <RouterLink to={props.to} className="link">
            {props.children}
        </RouterLink>
    );
}

export default Link;
