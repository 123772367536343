import { useState } from "react";

const MARGIN = 1;

function makeArray(from, to) {
    if (to - from <= 0) return [];
    const res = new Array(to - from);
    for (let i = 0; i < res.length; i++) {
        res[i] = i + from;
    }
    return res;
}

const Pagination = (props) => {
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState("");

    const onChange = (i) => {
        setOpen(false);
        setPage("");
        props.onChange(i);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newPage = Number(page);
        if (!isFinite(newPage) || newPage < 1 || newPage > props.total) {
            return;
        }
        onChange(newPage);
    };

    const btn = (i) => (
        <button
            key={i}
            className={[
                "page",
                i === props.current && "current",
                i === 1 && "first",
            ]
                .filter(Boolean)
                .join(" ")}
            onClick={() => onChange(i)}
        >
            {i}
        </button>
    );

    return (
        <div className="pagination">
            {props.current > 1 && (
                <button
                    className="mr-2 previous"
                    onClick={() => onChange(props.current - 1)}
                >
                    Назад
                </button>
            )}
            {btn(1)}
            {props.current - MARGIN - 1 > 1 && (
                <button
                    className="dots"
                    onClick={() => setOpen((open) => !open)}
                >
                    ...
                </button>
            )}
            {makeArray(
                Math.max(2, props.current - MARGIN),
                Math.min(props.total, props.current + MARGIN + 1)
            ).map(btn)}
            {props.current + MARGIN + 1 < props.total && (
                <button
                    className="dots"
                    onClick={() => setOpen((open) => !open)}
                >
                    ...
                </button>
            )}
            {props.total > 1 && btn(props.total)}
            {props.current < props.total && (
                <button
                    className="ml-2 next"
                    onClick={() => onChange(props.current + 1)}
                >
                    Вперёд
                </button>
            )}
            {open && (
                <div className="jump">
                    <div>Перейти к странице</div>
                    <form onSubmit={handleSubmit}>
                        <input
                            value={page}
                            onChange={(e) => setPage(e.currentTarget.value)}
                        />
                        <button>Выполнить</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Pagination;
