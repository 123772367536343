import { Link } from "react-router-dom";

const Button = (props) => {
    let classNames = ["button"];
    if (props.withBorders) {
        classNames.push("with-borders");
    }
    if (props.big) {
        classNames.push("big");
    }
    if (props.login) {
        classNames.push("login-button");
    }
    if (props.className) {
        classNames.push(props.className);
    }

    if (props.href) {
        return (
            <Link className={classNames.join(" ")} to={props.href}>
                {props.children}
            </Link>
        );
    }

    return (
        <button className={classNames.join(" ")} onClick={props.onClick}>
            {props.children}
        </button>
    );
};

export default Button;
